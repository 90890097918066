<template>
    <v-card
        flat
        min-width="300"
        class="questions-process questions-process--result"
    >
        <v-card-title class="d-flex text-truncate body-1">
            <span class="text-truncate">
                <slot name="title">{{ title }}</slot>
            </span>
        </v-card-title>
        <v-divider/>
        <v-card-text
            v-bind="vCardTextBindings"
        >
            <questions-process-summary
                :result="result"
            />
            <div
                v-for="(item, index) in replies"
                :key="index"
                :class="`mt-2 statistics-table d-flex align-center pt-3 ${index > 0 ? 'mt-3':''}`"
            >
                <w-icon
                    left
                    small
                    :color="item | replyIconColor"
                    value="mdi-checkbox-blank-circle"
                />
                <span
                    class="ms-2"
                    :class="item | replyTitleClass">
                    {{ item.title }}
                </span>
                <v-spacer></v-spacer>
                <v-chip
                    :color="item | replyIconColor"
                    small
                    class="v-chip-light-bg font-weight-semibold text-lowercase me-6"
                    :class="item | replyChipClass"
                >
                    {{ item.defaultScore }} {{ $transChoice('scores', item.defaultScore % 20) }}
                </v-chip>
            </div>
        </v-card-text>
        <v-divider/>
        <v-card-actions class="justify-center">
            <v-btn
                color="primary"
                depressed
                @click="$emit('close')"
            >
                {{ $trans('Close') }}
            </v-btn>
        </v-card-actions>
        <questions-process-preloader
            :loading="loading"
        />
    </v-card>
</template>

<script>
import QuestionsProcessPreloader from '@apps/questions/components/QuestionsProcess/QuestionsProcessPreloader'
import questionsProcessBaseMixin from '@apps/questions/components/QuestionsProcess/mixins/questionsProcessBaseMixin'
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'
import QuestionsProcessSummary from '@apps/questions/components/QuestionsProcess/QuestionsProcessSummary'

export default {
    name: 'QuestionsProcessResult',
    mixins: [questionsProcessBaseMixin, mobileBreakpointChecker],
    components: { QuestionsProcessSummary, QuestionsProcessPreloader },
    props: {
        title: String,
        result: Object,
        loading: {
            type: Boolean,
            default: false
        }
    },
    filters: {
        replyIconColor(item) {
            if(item.notReplied) return '#BDBDBD'

            return item.passed ? 'success' : 'error'
        },
        replyTitleClass(item) {
            if(item.notReplied) return 'text--disabled'

            return item.passed ? 'text--primary' : 'error--text'
        },
        replyChipClass(item) {
            if(item.notReplied) return 'text--disabled'

            return item.passed ? 'success--text' : 'error--text'
        }
    },
    computed: {
        replies() {
            return [].concat(this.result.replies, this.result.notReplied)
        }
    }
}
</script>

<style lang="scss">
@import '@/plugins/vuetify/default-preset/preset/mixins.scss';

.questions-process--result {
    .chart-wrapper {
        margin-top: -20px;
        max-width: 50%;
    }

    .statistics-table {
        border-top: solid 1px rgba(93, 89, 98, 0.14);
    }

    @include theme--child(stats-card-radial-bar) using($material) {
        #percent-chart {
            .apexcharts-canvas {
                .apexcharts-text {
                    &.apexcharts-datalabel-value {
                        fill: map-deep-get($material, 'text', 'primary');
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
</style>
