<template>
    <div
        class="d-flex align-center justify-space-between pb-1 mt-3"
    >
        <div>
            <div class="text-truncate">
                <span class="text-3xl text--primary">{{ result.scores }}</span>&nbsp;
                <span class="text-2xl">{{ $transChoice('scores', result.scores % 20) }}</span>
            </div>
            <div
                v-for="(item, i) in statistics"
                :key="`question-${i}`"
                class="text-truncate mt-1"
            >
                <label>{{ item.text }}:&nbsp;</label>
                <span
                    class="font-weight-bold"
                    :class="`${item.color}--text`"
                >
                            {{ item.value }}
                        </span>
            </div>
        </div>

<!--        <div v-show="!xsOnly && !hideChart" class="chart-wrapper">-->
<!--            <vue-apex-charts-->
<!--                :id="chartId"-->
<!--                type="radialBar"-->
<!--                class="d-flex align-center flex-grow-1 "-->
<!--                :options="chartOptions"-->
<!--                :series="[percentage]"-->
<!--            ></vue-apex-charts>-->
<!--        </div>-->
        <v-chip
            large
            :color="chartColor"
            class="font-weight-semibold"
        >
            {{ percentage }}%
        </v-chip>
    </div>
</template>

<script>
import moment from 'moment'
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'
// import VueApexCharts from 'vue-apexcharts'

export default {
    name: 'QuestionsProcessSummary',
    mixins: [ mobileBreakpointChecker ],
    // components: { VueApexCharts },
    props: {
        result: Object,
        hideChart: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        date() {
            return moment(this.result.end).format('DD.MM.YYYY HH:mm')
        },
        duration() {
            const duration = !this.result.duration
                ? null
                : ' / ' + this.result.duration

            let spentOfTime = this.result.spentOfTime.minutes + ':'
            spentOfTime += this.result.spentOfTime.seconds.toString().padStart(2, '0')

            if(duration) {
                spentOfTime += duration
            }

            return `${ spentOfTime } ${ this.$trans('min') }.`
        },
        percentage() {
            return this.result.percentage
        },
        chartColor() {
            const percentage = this.percentage
            let color = this.$vuetify.theme.currentTheme.success

            if(percentage < 50) {
                color = this.$vuetify.theme.currentTheme.error
            } else if(percentage < 85) {
                color = this.$vuetify.theme.currentTheme.warning
            }

            return color
        },
        chartOptions() {
            const chartOptions = {
                chart: {
                    sparkline: {
                        enabled: true
                    }
                },
                colors: [ this.chartColor ],
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        hollow: {
                            size: '65%'
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                fontSize: '1.25rem',
                                fontWeight: '600',
                                offsetY: 0
                            }
                        }
                    }
                },
                stroke: {
                    lineCap: 'round'
                }
            }

            return JSON.parse(JSON.stringify(chartOptions))
        },
        statistics() {
            return [
                {
                    text: this.$trans('Date'),
                    value: this.date,
                    color: 'secondary'
                },
                {
                    text: this.$trans('Duration'),
                    value: this.duration,
                    color: 'secondary'
                },
                {
                    text: this.$trans('Questions count'),
                    value: this.result.totalSets,
                    color: 'secondary'
                },
                {
                    text: this.$trans('Correct'),
                    value: this.result.correctReplies,
                    color: 'success'
                },
                {
                    text: this.$trans('Incorrect'),
                    value: this.result.incorrectReplies,
                    color: 'error'
                },
                {
                    text: this.$trans('Without reply'),
                    value: this.result.withoutReply,
                    color: 'grey lighten-2'
                }
            ]
        }
    },
    data() {
        return {
            chartId: 'percent-chart-' + Date.now()
        }
    }
}
</script>

<style lang=scss>

</style>
